import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import styles from './styles'

const HostedContentWrapper = ({ children }) => (
  <div className={css(styles.container.base)}>{children}</div>
)

HostedContentWrapper.propTypes = {
  children: PropTypes.any.isRequired
}

export default HostedContentWrapper
