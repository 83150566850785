import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import { ChevronIcon } from '@thebeansgroup/sb-ui/icons'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Callout } from '@thebeansgroup/sb-ui/typography'

import HostedContentWrapper from '@components/hosted_content_wrapper'

import styles from './styles'

const HostedHeader = ({ brand, t }) => {
  const renderBackButton = () => {
    if (!brand?.websiteLink) return null

    return (
      <div>
        <ChevronIcon
          colour={Colours('grey', '500')}
          rotateDegrees={180}
        />
        <a
          className={css(styles.link.text)}
          href={brand.websiteLink}
        >
          <Callout
            component='span'
            colour={Colours('grey', 500)}
          >
            {t('a_back_to')} {brand?.name}
          </Callout>
        </a>
      </div>
    )
  }

  const renderLogo = () => {
    return (
      <img
        className={css(styles.logo.img)}
        src={brand?.logo}
        alt={brand?.name}
      />
    )
  }

  return (
    <header className={css(styles.wrapper.base)}>
      <HostedContentWrapper>
        <div className={css(styles.wrapper.inner)}>
          {renderBackButton()}
          {renderLogo()}
        </div>
      </HostedContentWrapper>
    </header>
  )
}

HostedHeader.propTypes = {
  brand: PropTypes.shape({
    websiteLink: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}

export default createFragmentContainer(withI18n(HostedHeader), {
  brand: graphql`
    fragment hostedHeader_brand on Brand {
      websiteLink
      logo
      name
    }
  `
})
