import { StyleSheet } from 'aphrodite'

export const container = StyleSheet.create({
  base: {
    margin: '0 auto',
    maxWidth: '970px'
  }
})

export default {
  container
}
