import { graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'

import FlagshipProvider from '@src/components/flagship_provider'
import Template from '@src/components/template'

import HostedContentWrapper from '@components/hosted_content_wrapper'
import HostedHeader from '@components/hosted_header'
import OfferPageContentWrapper from '@components/offer_page_content_wrapper'
import Page from '@components/page'
import {
  getViewer,
  getConnectOfferPageFromViewer,
  getConnectOfferPagePropFromViewer
} from '@helpers/index'
import { getRouteParams, getRouteQueryParamValue } from '@helpers/routing'

import { getSelectedOfferId, getSelectedConsumerGroup } from '../../helpers'

const OfferPage = (props) => {
  const { country, slug } = getRouteParams(props)

  const queryVariables = {
    slug,
    countrySlug: country,
    enabled: true
  }

  const query = graphql`
    query hostedOfferQuery(
      $slug: ID!
      $countrySlug: String!
      $enabled: Boolean
    ) {
      viewer: accountsViewer {
        ...offerPageContentWrapper_viewer
        ...template_viewer
        connectOfferPage(
          slug: $slug
          countrySlug: $countrySlug
          enabled: $enabled
        ) {
          ...offerPageContentWrapper_connectOfferPage
          ...template_connectOfferPage
          brand {
            ...hostedHeader_brand
          }
          multiOffer
          uid
          offer {
            uid
          }
          offers {
            uid
            enabled
            status
            consumerGroup
          }
          domains
        }
      }
    }
  `

  return (
    <Page
      query={query}
      variables={queryVariables}
    >
      {({ props: queryProps, error, retry }) => {
        const viewer = getViewer(queryProps)
        const connectOfferPage = getConnectOfferPageFromViewer(queryProps)
        const offer = getConnectOfferPagePropFromViewer(queryProps, 'offer')
        const offers = getConnectOfferPagePropFromViewer(queryProps, 'offers')
        const offerUidFromParams = getRouteQueryParamValue(props, 'offer_uid')
        const consumerGroupFromParams = getRouteQueryParamValue(
          props,
          'consumer_group'
        )
        const selectedOfferId = getSelectedOfferId(
          offerUidFromParams,
          offers,
          connectOfferPage
        )
        const selectedCCG = getSelectedConsumerGroup(
          consumerGroupFromParams,
          connectOfferPage
        )

        return (
          <FlagshipProvider
            country={country}
            viewer={viewer}
          >
            <OfferPageContentWrapper
              loading={!queryProps}
              relayError={error}
              loaderText={props.t('m_loading_a_discount')}
              viewer={viewer}
              connectOfferPage={connectOfferPage}
              offer={offer}
              countryCode={country}
              selectedOfferId={selectedOfferId}
            >
              <HostedHeader brand={connectOfferPage?.brand} />
              <HostedContentWrapper>
                <Template
                  viewer={viewer}
                  connectOfferPage={connectOfferPage}
                  country={country}
                  slug={slug}
                  onLogout={retry}
                  selectedOfferId={selectedOfferId}
                  selectedCCG={selectedCCG}
                />
              </HostedContentWrapper>
            </OfferPageContentWrapper>
          </FlagshipProvider>
        )
      }}
    </Page>
  )
}

export default withI18n(OfferPage)
