import { StyleSheet } from 'aphrodite'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { sister } from '@thebeansgroup/sb-ui/style_helpers/queries'

const wrapper = StyleSheet.create({
  base: {
    position: 'relative',
    padding: '5px 32px',
    backgroundColor: Colours('white', 300),
    boxShadow: '0px 2px 4px 0px rgba(23,27,30,0.101)'
  },

  inner: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: '90px',
    position: 'relative',

    ...sister({
      justifyContent: 'space-between'
    }).andBelow()
  }
})

const logo = StyleSheet.create({
  img: {
    maxHeight: '80px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    ...sister({
      position: 'relative',
      top: 'unset',
      left: 'unset',
      transform: 'unset',
      height: '80px'
    }).andBelow()
  }
})

const link = StyleSheet.create({
  text: {
    color: Colours('grey', 500),
    marginLeft: '12px'
  }
})

export default {
  wrapper,
  logo,
  link
}
